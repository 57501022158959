import { Apple } from '@mui/icons-material';

export default function About() {
    return (
        <div className="flex flex-col items-center justify-center h-full">
            <h1>About</h1>
            <p>
                Everwhz is a podcast player that allows you to browse and listen to your favorite history podcasts.
            </p>
            <p>
                <a 
                    href="https://apps.apple.com/us/app/everwhz/id6504833826" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                    <Apple /> Download on the App Store
                </a>
            </p>
            <p>
                feedback appreciated! please email me at <a className="text-blue-500" href="mailto:anthonylouisburns@gmail.com">anthonylouisburns@gmail.com</a>
            </p>
        </div>
    );
}