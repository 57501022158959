import { useAuthActions } from "@convex-dev/auth/react";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import Apple from "@mui/icons-material/Apple";
import { Button, Typography } from "@mui/material";
import { View } from "react-native-web";

export function SignIn() {
  const { signIn } = useAuthActions();
  return (
    <View style={{ 
      display: "flex", 
      flexDirection: "column", 
      gap: 10,
      alignItems: "center",
      margin: "0 auto"
    }}>
      <Button onClick={() => void signIn("github")} sx={{ border: "1px solid #e0e0e0", minWidth: "240px" }}>
        <GitHubIcon />&nbsp;&nbsp;<Typography fontSize={16}>Sign in with Github</Typography>
      </Button>
      <Button onClick={() => void signIn("google")} sx={{ border: "1px solid #e0e0e0", minWidth: "240px" }}>
        <GoogleIcon />&nbsp;&nbsp;<Typography fontSize={16}>Sign in with Google</Typography>
      </Button>
      <Button onClick={() => void signIn("apple")} sx={{ border: "1px solid #e0e0e0", minWidth: "240px" }}>
        <Apple />&nbsp;&nbsp;<Typography fontSize={16}>Sign in with Apple</Typography>
      </Button>
    </View>
  );
}
