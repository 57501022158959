import Player from "./Player";
import { Id } from "@packages/backend/convex/_generated/dataModel";

export default function PlayerHolder({
  playerEpisodeId,
  setPlayerEpisodeId,
  deviceId,
}: {
  playerEpisodeId: string;
  setPlayerEpisodeId: (playerEpisodeId: string) => void;
  deviceId: string;
}) {
  if (!playerEpisodeId || playerEpisodeId == "") {
    return <></>;
  }

  return <Player player_episode_id={playerEpisodeId as Id<"episode">} setPlayerEpisodeId={setPlayerEpisodeId} device_id={deviceId} />;
}

