import { useQuery, useMutation } from "convex/react";
import { api } from "@packages/backend/convex/_generated/api";
import { Id } from "@packages/backend/convex/_generated/dataModel";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { IconButton, Typography } from "@mui/material";
import { PlayCircleOutline, PauseCircleOutline, ShuffleOnOutlined, Podcasts, Timeline, ReplaySharp, SkipNextRounded, SkipPreviousRounded, Close } from "@mui/icons-material";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

export default function Player({ player_episode_id, setPlayerEpisodeId, device_id }: { player_episode_id: Id<"episode"> | undefined, setPlayerEpisodeId: (playerEpisodeId: string) => void, device_id: string }) {
  const [isTimelineVisible, setIsTimelineVisible] = useState(false);
  const [isShuffle, setIsShuffle] = useState(false);
  // [ ] set initial position
  // [ ] update position as plays
  // [ ] last, next, random, podcast and timeline
  // [ ] filtered timeline 
  // [ ] don't include already played episodes
  const episodeName = useQuery(api.everwhz.episodeName, { id: player_episode_id });
  const { episode, podcast } = episodeName ?? {};
  const playStatus = useQuery(api.everwhz.getPlayStatus, { id: player_episode_id });
  const updatePlayStatus = useMutation(api.everwhz.playStatus);
  const audioRef = useRef<AudioPlayer>(null);
  const [duration, setDuration] = useState(0);
  const offset = player_episode_id ? useQuery(api.page_timeline.indexOfEpisode, { episode_id: player_episode_id }) : undefined  ;
  const nextEpisodes = player_episode_id ? useQuery(api.everwhz.getNextEpisodes,
    {
      currentEpisodeId: player_episode_id,
      source: isTimelineVisible ? "timeline" : "podcast"
    }) : undefined;

  const handleLoadedMetadata = () => {
    if (audioRef.current && playStatus?.position) {
      console.log("Setting current time to:", playStatus.position / 1000, "seconds");
      audioRef.current.audio.current.currentTime = playStatus.position / 1000;
    }
    if (audioRef.current?.audio.current?.duration) {
      console.log("Setting duration to:", audioRef.current.audio.current.duration, "seconds");
      setDuration(audioRef.current.audio.current.duration * 1000);
    }
  };

  const handleTimeUpdate = () => {
    // console.log("Time update:", audioRef.current?.audio.current?.currentTime, "seconds");
    const pos = audioRef.current?.audio.current?.currentTime ?? 0;

    if (player_episode_id) {
      updatePlayStatus(
        {
          id: player_episode_id,
          device_id: device_id,
          position: pos * 1000,
          duration: duration,
        });
    }
  }

  const timelineColor = isTimelineVisible ? "text-cyan-500 hover:text-cyan-700" : "text-purple-500 hover:text-purple-700";
  const shuffleColor = isShuffle ? "text-yellow-500 hover:text-yellow-700" : "";
  const shuffleNextColor = isShuffle ? timelineColor + " rounded-full outline-2 outline-yellow-500 hover:outline-yellow-700" : timelineColor;

  const handleNextEpisode = (episodeId: Id<"episode"> | undefined) => {
    if (episodeId) {
      setPlayerEpisodeId(episodeId);
    }
  }

  return (
    <div className="header-center">
      <div>
        <AudioPlayer
          ref={audioRef}
          listenInterval={1000}
          onListen={handleTimeUpdate}
          onLoadedMetaData={handleLoadedMetadata}
          header={<div className="text-center">
            <div className="text-center">
              <Link to={`/timeline?index=${offset}`}><div className="heavy" dangerouslySetInnerHTML={{ __html: episode?.title ?? "-" }} /></Link>
            </div>

            <div className="text-center">
              <Link to={`/episodes/${episode?.podcast_id}`}>{podcast?.title}</Link>
            </div>

            <div className="text-center">
              <IconButton onClick={() => setIsShuffle(!isShuffle)}>
                <ShuffleOnOutlined className={shuffleColor} />
              </IconButton>
              <IconButton onClick={() => setIsTimelineVisible(!isTimelineVisible)} sx={{ flexDirection: 'column' }}>
                {isTimelineVisible ?
                  <>
                    <Timeline className={timelineColor} />
                    <Typography variant="caption">timeline</Typography>
                  </>
                  :
                  <>
                    <Podcasts className={timelineColor} />
                    <Typography variant="caption">podcast</Typography>
                  </>
                }
              </IconButton>
              <IconButton onClick={() => setPlayerEpisodeId("")}>
                <Close className="text-red-500 hover:text-red-700" />
              </IconButton>
            </div>
          </div>}
          footer={<div>
            <div>
              <IconButton>
                <SkipPreviousRounded className={timelineColor} 
                onClick={() => handleNextEpisode(nextEpisodes?.previous?._id)} />
              </IconButton>
              <span className="text-xs">
                {nextEpisodes?.previous?.title},
                {nextEpisodes?.previous?.podcast_title} #{nextEpisodes?.previous?.episode_number}
              </span>
            </div>
            <div>
              <IconButton>
                <SkipNextRounded className={shuffleNextColor} 
                onClick={() => handleNextEpisode(isShuffle ? nextEpisodes?.random?._id : nextEpisodes?.next?._id)} />
              </IconButton>
              {isShuffle ?
                <span className="text-xs">
                  {nextEpisodes?.random?.title},
                  {nextEpisodes?.random?.podcast_title} #{nextEpisodes?.random?.episode_number}
                </span>
                :
                <span className="text-xs">
                  {nextEpisodes?.next?.title},
                  {nextEpisodes?.next?.podcast_title} #{nextEpisodes?.next?.episode_number}
                </span>
              }
            </div>
          </div>}
          autoPlay
          src={episode?.mp3_link}
          onEnded={() => console.log("ended")}
          progressJumpSteps={{ backward: 15000, forward: 15000 }}
          layout="stacked"
          showSkipControls={true}
          showJumpControls={true}
          loop={false}
          customIcons={{
            play: <IconButton>
              <PlayCircleOutline className="text-green-500 hover:text-green-700" />
            </IconButton>,
            pause: <IconButton>
              <PauseCircleOutline className="text-red-500 hover:text-red-700" />
            </IconButton>,
            rewind: <IconButton>
              <ReplaySharp className="text-black" />
            </IconButton>,
            forward: <IconButton>
              <ReplaySharp className="text-black rotate-y-180" />
            </IconButton>,
            previous: <IconButton>
              <SkipPreviousRounded className={timelineColor} 
                onClick={() => handleNextEpisode(nextEpisodes?.previous?._id)} />
            </IconButton>,
            next: <IconButton>
              <SkipNextRounded className={shuffleNextColor} 
                onClick={() => handleNextEpisode(isShuffle ? nextEpisodes?.random?._id : nextEpisodes?.next?._id)} />
            </IconButton>,
            loop: <></>,
            loopOff: <></>
          }}
        />
        <div className="flex flex-row items-center justify-center w-full gap-2 mt-[10px]" />

      </div>
    </div >
  );
}
